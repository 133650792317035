.left-column {
  width: 400px !important;
  background-color: #f8f9fa;
  position: relative;
}

.preview {
  width: 500px !important;
  background-color: #ffffff;
}

html, body {
  height: 100%;
  margin: 0;
  background-color: #e9ecef;
}

.editor-h {
  height: calc(100%);
  background-color: #ffffff;
}

.preview-content {
  height: 100%;
  overflow: auto;
}

.bg-dark {
  background-color: #343a40 !important;
}

.btn-primary {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-danger {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.list-group-item {
  background-color: #f8f9fa !important;
  position: relative;
}

.list-group-item.active {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.input-group select {
  max-width: 70px !important;
}

.list-group-item .btn-danger {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.left-column .file-upload-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
}

.left-column .file-upload-button .btn {
  flex: 1;
  margin: 0 5px;
  padding: 0.25rem 0.5rem;
}

.git-panel {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.git-panel button {
  flex: 1;
  margin: 5px;
  padding: 0.25rem 0.5rem;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.ReactModal__Overlay {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Content {
  width: 50%;
  height: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
}

.file-list-container {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  margin-bottom: 20px;
}

.list-group-item {
  white-space: normal;
}

.file-path-part {
  display: inline-block;
}

.delete-button {
  opacity: 0.5;
  transition: opacity 0.3s ease;
  font-size: 1.2rem;
  padding: 0 0.5rem;
}

.delete-button:hover {
  opacity: 1;
}

.current-file-path {
  font-size: 0.9rem;
  color: #6c757d;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editor-container {
  height: calc(100% - 40px);
  width: 100%;
}

.monaco-editor-container {
  width: 100% !important;
  height: 100% !important;
}

.monaco-editor {
  width: 100% !important;
  height: 100% !important;
}

.monaco-editor .overflow-guard {
  width: 100% !important;
  height: 100% !important;
}

.monaco-editor .view-lines {
  width: 100% !important;
  height: 100% !important;
}