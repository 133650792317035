body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e9ecef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes rotate {
    0% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(540deg);
    }
}

.rotating-text {
    display: inline-block;
    animation: rotate 3s linear infinite;
}